<template>
	<div class="box">
		 
		
		<div class="member-info" v-loading="loading">
			       <div class="authstatus_wait" v-if="auth_id>0&&auth_status==0">状态：待审核,可编辑</div>
			       <div class="authstatus" v-if="auth_id>0&&auth_status==1">状态：已通过,不可编辑</div>
			       <div class="authstatus_re" v-if="auth_id>0&&auth_status==-1">状态：已拒绝,{{auth_reason}}</div>
					<el-form ref="infoRef" :model="memberInfo" :rules="infoRules" label-width="80px">
						<el-form-item label="账号" v-if="memberInfo.userName">
							<p>{{ memberInfo.userName }}</p>
							
						</el-form-item>
						<!-- <el-form-item label="邮箱">
							<p v-if="memberInfo.email">{{ memberInfo.email }}</p>
							<p v-else class="toBind" @click="$router.push({ path: '/member/security' })">去绑定</p>
						</el-form-item> -->
						 
						<el-form-item label="公司名称" prop="nickName"><el-input :disabled="auth_status>0" v-model="companyname"></el-input></el-form-item>
					    <el-form-item label="信用代码" prop="nickName"><el-input :disabled="auth_status>0" v-model="companyno"></el-input></el-form-item>
					    
						<div class='el-tab-pane'>
					    					<div class="preview">
					    						<div class="title">营业执照</div>
					    						 
					    					</div>
					    					<el-upload 
					    					:action="uploadActionUrl" 
					    					:show-file-list="false" 
					    					:on-success="handleAvatarSuccess" 
					    					class="upload"
					    					 
					    					>
					    						<div class="img-wrap"  >
					    							<img :src="$img(defaultimg)"  /></div>
					    					</el-upload> 
					    
					    					 
					    </div>
					</el-form>
					<div class="btn"><el-button size="medium" type="primary":disabled="auth_status>0" @click="saveInfo">保存</el-button></div>
				 
		</div>
	</div>
</template>

<script>
import { info, memberauth, headImg } from '@/api/member/info';
import Config from '@/utils/config';
import { mapGetters } from 'vuex';
import { email } from '@/api/member/security';
export default {
	name: 'info',
	components: {},
	data: () => {
		return {
			memberInfo: {
				userHeadImg: '',
				userName: '', //账号
				nickName: '', //昵称
				email: '',
				tell: ''
			},
			infoRules: {
				nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }, { max: 30, message: '最大长度为30个字符', trigger: 'blur' }]
			},
			activeName: 'first',
			loading: true,
			uploadActionUrl: Config.baseUrl + '/api/upload/headimg',
			imgUrl: '',
			defaultimg:'/public/upload.png',
			companyname:'',
			companyno:'',
			auth_id:0,
			auth_status:0,
			auth_reason:0,
			yes: true
		};
	},
	created() {
		this.getInfo();
	},
	mounted() {
		 
	},
	methods: {
		getInfo() {
			info()
				.then(res => {
					if (res.code == 0) {
						//console.log(".............",JSON.stringify(res.data))
						this.memberInfo.userHeadImg = res.data.headimg;
						this.memberInfo.userName = res.data.username;
						this.memberInfo.nickName = res.data.nickname;
						this.memberInfo.email = res.data.email;
						this.memberInfo.tell = res.data.mobile;
						if(res.data.authinfo){
							this.auth_id=res.data.authinfo.auth_id;
							this.auth_status=res.data.authinfo.status; 
							this.defaultimg=res.data.authinfo.auth_card_back;
							this.companyname=res.data.authinfo.auth_card_hand;
							this.companyno=res.data.authinfo.auth_card_front;
							this.auth_reason=res.data.authinfo.remark;
						}
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error(err.message);
				});
		},
		handleClick(tab, event) {},
		saveInfo() { 
			     if(this.yes==false){
					 this.$message.error('不要重复提交');
					 return false;
				 }
				 if(this.companyname==''){
					 this.$message.error('请输入公司名称');
					 return false;
				 }
				 if(this.companyno==''){
				 	 this.$message.error('统一社会信用代码');
					 return false
				 }
				 if(this.defaultimg=='/public/upload.png'){
				 	 this.$message.error('请上传图片');
					 return false
				 }
			if(this.yes==true){
				 this.yes=false;
				  memberauth({ auth_id:this.auth_id,companyname: this.companyname,companyno:this.companyno,defaultimg:this.defaultimg,username:this.memberInfo.userName })
							.then(res => {
								if (res.code == 0) {
									 
									this.$message({ message: '提交成功 请等待审核', type: 'success' });
									this.$router.go(0);
								}
							})
							.catch(err => {
								this.$message.error(err.message);
							});
				 
		  }	 
		},
		handleAvatarSuccess(res, file) {
			this.defaultimg = res.data.pic_path;
			 
		},
		uploadHeadImg() {
			headImg({ headimg: this.imgUrl })
				.then(res => {
					if (res.code == 0) {
						this.$message({ message: '头像修改成功', type: 'success' });
						this.$store.dispatch('member/member_detail', { refresh: 1 });
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		}
	},
	computed: {
		...mapGetters(['defaultHeadImage'])
	}
};
</script>
<style>
.member-info .el-upload {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}
</style>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.member-info {
	background: #ffffff;
	padding: 20px;
	.authstatus{
		color:#4CAF50;
	}
	.authstatus_wait{
		color:#03A9F4;
	}
	.authstatus_re{
		color:#FF0036;
	}
	.el-tab-pane {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		.preview {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			.title {
				margin-right: 20px;
				line-height: 3;
			}
			.content {
				color: $base-color;
				line-height: 1.5;
			}
		}
		.upload {
			display: flex;
			justify-content: center;
			
		}
		.el-upload {
			width: 120px;
		}
		.img-wrap {
			width: 500px;
			height: 200px;
			display: block;
			line-height: 200px; 
		    border:dotted 1px #F56C6C;
			broder-radius:10px;
			cursor: pointer;
			img {
				width:95%;
				height:95%;
			}
		}
		.el-form {
			margin-top: 20px;
			width: 500px;
			margin-left: 200px;
			 
			.toBind {
				cursor: pointer;
				&:hover {
					color: $base-color;
				}
			}
		}
		
	}
}
.btn {
			text-align: center;
			 
		}
</style>
